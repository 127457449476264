import React, {useEffect} from 'react';
import {EmailIcon, LinkedinIcon, TwitterIcon} from "../icons";
import {isMobile} from "react-device-detect";
import cx from "classnames";
import { addTimeout } from '../utils/timeManager';

const AuthorDetails = ({author, className, deferImage, nameClass, bioClass}) => {
    className = cx(
        "bg-gray-220 p-12 font-sans author-details",
        className
    );
    nameClass = cx(
        nameClass || "font-outfit text-3xl"
    );
    bioClass = cx(
        bioClass || "text-lg leading-tight font-light my-4"
    );

    useEffect(() => {
        if(!deferImage) {
            return;
        }
        addTimeout(() => {
            const img = document.getElementById('authorDetailsImage');
            if (img && img.getAttribute('data-src')) {
                img.setAttribute('src', img.getAttribute('data-src'));
            }
        }, 500 * (isMobile ? 4 : 1));
    }, [deferImage]);

    return (
        <div className={className} data-datocms-noindex>
            <div className="flex items-center mb-3">
                {deferImage && <img className="rounded-full align-middle" id={"authorDetailsImage"} width={96} height={96} alt={author.name}
                                          data-src={author.profilePhoto && author.profilePhoto.url ? `${author.profilePhoto.url}&w=128&h=128&fit=crop&auto=compress` : `/images/logos/acronym.png`}/>}
                {!deferImage && <img className="rounded-full align-middle" width={96} height={96} alt={author.name}
                                          src={author.profilePhoto && author.profilePhoto.url ? `${author.profilePhoto.url}&w=128&h=128&fit=crop&auto=compress` : `/images/logos/acronym.png`}/>}
                <div className="ml-5">
                    <div className="font-news text-4xl leading-none italic capitalize -mb-2">Author</div>
                    <div className={nameClass}>{author.name}</div>
                </div>
            </div>
            <div className={bioClass} dangerouslySetInnerHTML={{__html: author.bio}}/>
            <div className="flex social-container">
                {author.linkedin && <a href={author.linkedin} target="_blank" rel="noopener noreferrer" className="mr-2">
                    <LinkedinIcon iconFillColor={"#042634"} bgFillColor={"transparent"} size={46} round={true}/>
                </a>}
                {author.email && <a href={`mailto:${author.email}`} target="_blank" rel="noopener noreferrer" className="mr-2">
                    <EmailIcon iconFillColor={"#FFF"} bgFillColor={"#000"} size={46} round={true}/>
                </a>}
                {author.twitter && <a href={author.twitter} target="_blank" rel="noopener noreferrer">
                    <TwitterIcon iconFillColor={"#FFF"} bgFillColor={"#000"} size={46} round={true}/>
                </a>}
            </div>
        </div>
    )
}

export default AuthorDetails
