import React, {useState} from 'react'
import {graphql} from 'gatsby'
import { ArticleList } from '@cg-squad/ui-components'
import Layout from '../components/layout'
import {Basic, Facebook, Twitter} from '../components/SEO'
import {metadata} from '../../config'
import loadable from "@loadable/component";
import AuthorDetails from "./authorDetails";
import SeeMore from "../components/buttons/SeeMore";
import Helmet from "react-helmet";

const PopularPosts = loadable(() => import("../components/popularPosts"));

// The pages created in gatsby-node.js will use this component("template" by gatsby convention)
// The data is recieved by the graphQL query from the bottom of this component
const AuthorPage = props => {

    const profile = props.data.author.bio

    // SEO - title (Page, twitter, facebook)
    const title = props.data.author.name

    // SEO - url (Page, twitter, facebook)
    const url = `${metadata.url}/authors/${props.data.author.slug}`

    const seo = {
        title: `${metadata.defaultTitle} | ${title}`,
        description: profile,
        image: props.data.author.profilePhoto
    }

    const [articles, setArticles] = useState(props.data.allDatoCmsArticle.nodes.splice(0, 9));

    const seeMore = () => {
        setArticles(articles.concat(props.data.allDatoCmsArticle.nodes.splice(0, 9)));
    }

    return (
        <Layout relativePath={"/"}>
            <Basic seo={seo}/>
            <Twitter seo={seo} url={url}/>
            <Facebook seo={seo} url={url}/>
            <Helmet>
                <link rel="canonical" href={url}/>
            </Helmet>
            <main className="wrapper" role="main" data-datocms-noindex>
                <div className={"w-full flex"}>
                    <article className={"flex-grow-3"}>
                        <AuthorDetails author={props.data.author}/>
                        <div className="my-7 lg:my-10 font-libre text-3xl capitalize text-center lg:text-left">Latest articles</div>
                        <ArticleList articles={articles} headingMargin={0}/>
                        {props.data.allDatoCmsArticle.nodes.length > 0 && <SeeMore onSeeMoreClick={seeMore}/>}
                        {/*<hr className={"mb-4"}/>
                        <CategoryTitle title="Latest Articles"/>
                        <div>
                            <WindowScroller>
                                {({height, isScrolling, onChildScroll, scrollTop, width}) => (
                                    <List
                                        autoHeight
                                        height={height}
                                        isScrolling={isScrolling}
                                        onScroll={onChildScroll}
                                        rowCount={props.data.allDatoCmsArticle.totalCount}
                                        rowRenderer={rowRenderer}
                                        scrollTop={scrollTop}
                                        rowHeight={rowHeight}
                                        width={width}
                                        style={{width: "unset !important"}}
                                    />
                                )}
                            </WindowScroller>
                        </div>*/}
                    </article>
                    <PopularPosts/>
                </div>
            </main>
        </Layout>
    )
}

// in gatsby-node.js we gave a context "slug"
// we can use that to filter the matching publication and slug now
export const query = graphql`
    query AuthorQuery($slug: String!) {
        author: datoCmsAuthor(slug: {eq: $slug}) {
            bio
            linkedin
            email
            twitter
            profilePhoto {
                url,
                width,
                height
            }
            name
            slug
            portfolio
        }
        allDatoCmsArticle (filter: {author: {slug: {eq: $slug}}, website: {elemMatch: {name: {eq: "pt"}}}}, sort: {publishedAt: DESC}) {
            nodes {
                heading
                excerpt
                excerptNode {
                  childMarkdownRemark {
                    html
                  }
                }
                slug
                author {
                    name
                    slug
                }
                category {
                    title
                    slug
                    treeParent {
                        slug
                    }
                    position
                    website {
                        name
                    }
                }
                cover {
                    url
                    gatsbyImageData(
                        layout: FULL_WIDTH,
                        placeholder: BLURRED,
                        forceBlurhash: false,
                        imgixParams: { auto: "compress", fit: "crop", w: "450", q: 40, ar: "3:2" }
                    )
                    width
                    height
                }
                publishedAt
                content
            }
            totalCount
        }
    }
`
export default AuthorPage
